@import "../../UI/variables";

.modal {
    h3 {
        font-size: 2rem;
        margin-bottom: 1rem; }
    .close {
        position: absolute;
        right: 17px;
        top: 35px; } }

.error {
    color: $red; }
