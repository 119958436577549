@import "../../UI/mixins";

.DropZoneWrap {
    background: #cdcfe3;
    padding: 30px 0;
    min-height: 402px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    @include sm-screen {
        min-height: 320px; } }

.dropZone {
    font-size: 2rem;
    border: 1px dashed #292C4A;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 720px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    word-break: break-word;
    padding: 40px 60px;
    cursor: pointer;
    @include sm-screen {
        display: block;
        padding: 30px 16px;
        font-size: 1.8rem;
        text-align: center; }
    &:before {
        content: '';
        width: 260px;
        height: 260px;
        background: url(../../assets/img/target.svg)no-repeat center / contain;
        flex-shrink: 0;
        margin-right: 20px;
        display: block;
        @include sm-screen {
            margin: 0 auto;
            width: 150px;
            height: 150px; } } }

.title {
    font-size: 36px; }


.pgw {
    max-width: 530px;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    .lb {
        margin-bottom: 20px; } }

