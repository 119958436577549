@import "../../UI/variables";
@import "../../UI/mixins";

.Header {
    background: #292c4a;
    padding: 8px 0;
    color: #ffffff;
    nav {
        display: flex;
        align-items: center;
        @include md-screen {
            opacity: 0;
            visibility: hidden;
            transition: all ease .5s;
            position: absolute;
            z-index: 2;
            background: $primary;
            margin-top: 8px;
            top: 160%;
            right: 0;
            left: 0;
            flex-direction: column-reverse;
            align-items: flex-start;
            padding: 0 1rem .5rem; }
        &.open {
            opacity: 1;
            visibility: visible;
            top: 100%; }
        .link {
            display: flex;
            align-items: center;
            margin-left: .5rem;
            @include md-screen {
                margin: .5rem 0; } } }
    .select {
        color: #ffffff;
        font-size: 1rem;
        margin-right: 1rem;
        @include md-screen {
            margin: .5rem 0 0; }
        &:before {
            display: none; }
        svg {
            width: .75em; } }
    .root {
        padding: 3px;
        align-items: center;
        display: flex; }
    .icon {
        color: #ffffff; }

    .callMenu {
        padding: 0;
        background: transparent;
        border: none;
        color: #ffffff;
        @include min-md-screen {
            display: none; }
        svg {
            font-size: 2rem; } } }

.link {
    color: #ffffff;
    transition: $transition;
    &:hover {
        color: $secondary; }
    svg {
        width: .75em;
        height: .75em; } }

.innerContainer {
    max-width: 100%; }

body {
    .option {
        font-size: 1rem;
        padding: 5px 8px; }
    .flag {
        width: 1rem;
        vertical-align: sub;
        margin-right: .3rem; } }

.row {
    display: flex;
    align-items: center;
    justify-content: space-between; }


.profileWrap {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    @include md-screen {
        display: block;
        margin: 0; }
    .name {
        font-weight: 700;
        margin-right: 2rem;
        @include md-screen {
            margin: 0; } }
    .authBtn {
        margin-left: 1rem;
        padding: 0;
        @include md-screen {
            margin: 0; } } }


.authBtn {
    background: transparent;
    border: none;
    color: #ffffff;
    transition: $transition;
    display: flex;
    align-items: center;
    &:hover {
        color: $secondary; }
    svg {
        margin-left: .5rem;
        width: .75em;
        height: .75em; } }
