@import "../../UI/variables";

.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 108px);
    padding: 50px 30px;
    img {
        max-width: 100%;
        margin: 0 auto; }
    .cardWrap {
        background: #eff2f7;
        max-width: 950px;
        width: 100%; } }


.form {
    margin: 1.5rem 0; }

.btnWrap {
    margin-top: 1rem; }

.separator {
    margin: 20px 0;
    text-align: center; }

.linksWrap {
    margin-top: 16px;
    text-align: center;
    a {
        color: $secondary;
        text-decoration: underline;
        display: inline-block;
        margin: 0 5px;
        &:hover {
            text-decoration: none; } } }

.formWrap {
    background: #ffffff; }

.stepTitle {
    margin-bottom: .75rem; }

.accountType {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; }
    svg {
        width: 2rem;
        height: 2rem; } }

.accTpWr {
    padding: 1rem 0; }

.backBtn {
    display: flex;
    background: transparent;
    color: $secondary;
    align-items: center;
    border: none;
    svg {
        margin-right: .5rem;
        width: .75rem; } }
