.sc {
    text-align: center;
    padding: 50px 0;
    h2 {
        text-align: center;
        font-size: 18px; }
    img {
        max-width: 100%;
        margin: 30px auto; }
    a {
        color: #0a0a0a;
        font-size: 18px;
        font-weight: 700;
        transition: all ease .25s;
        &:hover {
            color: #ff6565; } } }

.linkWrap {
    font-size: 20px;
    margin-bottom: 20px; }
