@import "../../UI/variables";
@import "../../UI/mixins";

.Footer {
    background: $primary;
    padding: 12px 0;
    @include sm-screen {
        text-align: center; }
    .logo {
        display: inline-block;
        width: 140px;
        @include sm-screen {
            margin-bottom: 10px; }
        img {
            width: 100%; } }
    .link {
        margin: 0 30px; }
    button,
    .link {
        color: $bg-primary;
        display: inline-block;
        transition: $transition;
        padding: 0;
        background: transparent;
        border: 0;
        @include sm-screen {
            display: block;
            margin: 0 auto 10px; }
        &:hover {
            color: $secondary; } } }



.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sm-screen {
        display: block; } }

.innerContainer {
    max-width: 100%; }
