@import "../../../UI/mixins";

.textareaWrap {
    position: relative; }

.close {
    position: absolute;
    background: transparent;
    right: 5px;
    top: 5px;
    border: 0;
    z-index: 5;
    cursor: pointer; }

.row {
    display: flex;
    position: relative;
    margin: 0 -5px;
    @include sm-screen {
        display: block; } }

.col {
    width: 50%;
    padding: 0 5px;
    position: relative;
    @include sm-screen {
        width: 100%; } }

.fll {
    position: absolute;
    right: 100%;
    font-weight: 700;
    top: 12px;
    width: 46px;
    font-size: 16px;
    @include sm-screen {
        position: static;
        padding: 16px 5px 5px; } }
