.password{
    display: flex;
    align-items: center;
}
.password img{
    width: 16px;
}
.w{
    font-weight: 700;
    padding-right: 16px;
}