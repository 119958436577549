html {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

input, textarea, button, select {
    outline: none;
    font: inherit;
    margin: 0;
    border-radius: 0
}

input:active, input:focus, textarea:active, textarea:focus, button:active, button:focus, select:active, select:focus {
    outline: none
}

button, input[type="submit"], input[type="button"] {
    -webkit-appearance: none;
    cursor: pointer
}

h1, .h1, h2, .h2, h3, .h3 {
    margin: 0
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: inherit
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent
}

header, nav, section, article, aside, footer, main, picture {
    display: block
}

textarea {
    resize: none;
    font: inherit
}

ol, ul {
    list-style: none
}

img {
    border: none;
    display: block
}

input {
    vertical-align: middle
}

a {
    text-decoration: none
}

a:hover {
    text-decoration: none
}

ul, li {
    list-style-type: none
}

@font-face {
    font-family: 'Open Sans';
    src: url(./assets/fonts/OpenSans-Regular.ttf);
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url(./assets/fonts/OpenSans-SemiBold.ttf);
    font-weight: 700;
    font-display: swap;
}

html{
    font-size: 14px;
}
body{
    font-family: -apple-system,BlinkMacSystemFont,OpenSans,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    background: #f0f0f7;
}

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#root main{
    flex: 100%;
}

.container{
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    position: relative;
}

.react-pdf__Page__annotations.annotationLayer{
    display: none;
}

h1{
    font-size: 2.2rem;
}
h2{
    font-size: 2rem;
}
h3{
    font-size: 1.8rem;
}
h4{
    font-size: 1.4rem;
}
h5{
    font-size: 1.2rem;
}
h6{
    font-size: 1.1rem;
}
h1,
h2,
h3,
h4,
h5,
h6{
    margin-bottom: .5rem;
}

.scrollbar-container{
    width: 100%;
    position: relative;
    overflow: hidden;
}

.scrollbar-container .ps__rail-y{
    position: absolute;
    right: 0;
    left: auto !important;
    width: 10px;
    background: rgba(41, 44, 74, .1);
    transition: opacity ease .25s;
    opacity: .5;
}
.scrollbar-container.ps--scrolling-y .ps__rail-y,
.scrollbar-container .ps__rail-y.ps--clicking,
.scrollbar-container .ps__rail-y:hover{
    opacity: 1;
}
.scrollbar-container .ps__thumb-y{
    border-radius: 4px;
    width: 100%;
    background: #3b44a6;
    position: absolute;
    right: 0;
}
