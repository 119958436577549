.p {
    padding: 110px 0 50px;
    text-align: center; }

.p h1 {
    font-size: 220px;
    color: #3b44a6;
    margin-bottom: 12px; }

.p h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 72px; }

.p a {
    color: #0a0a0a;
    font-size: 18px;
    font-weight: 700;
    transition: all ease .25s; }

.p a:hover {
    color: #ff6565; }

@media (max-width: 767px) {
    .p {
        padding: 50px 0; }

    .p h1 {
        font-size: 150px;
        color: #3b44a6;
        margin-bottom: 12px; }

    .p h3 {
        margin-bottom: 30px; } }

