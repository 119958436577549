.form{
    max-width: 530px;
    margin: 0 auto;
    font-size: 12px;
    padding: 17px 0;
}
.form .MuiInputBase-root{
    margin-bottom: 10px;
}

.target{
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.formWrap{
    margin-top: 17px;
}
.sw{
    margin: 0 12px;
    display: flex;
}
.sw img{
    margin: 0 3px;
}
.btw{
    display: flex;
    padding-top: 12px;
}
.btw .bs{
    margin-right: 10px;
    min-width: 50px;
    padding: 5px;
}