$primary: #292C4A;
$secondary: #FF6565;
$secondary-light: #ff7171;
$warning: #FFBA65;
$success: #51CC51;

$green: #00B38B;
$red: #F72F2F;
$gray: #626262;



$text-primary: #0A0A0A;

$bg-primary: #FDFDFD;

$transition: all ease .25s;

$box-shadow: 0 0 2px rgba(10, 10, 10, .14), 0 2px 2px rgba(10, 10, 10, .12), 0 0 2px rgba(10, 10, 10, .14);
$radius: 4px;
